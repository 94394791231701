@import url("../fonts/fonts.css");
@import url("../css/icons.css");

html,
body {
  padding: 0;
  margin: 0;
  font-size: 16px;
  line-height: 1.5;
  font-weight: normal;
  font-family: Product Sans;
  background-color: #fff;
  scroll-behavior: smooth;
}

body::-webkit-scrollbar {
  width: 6px;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: #51aa74;
}
*,
*::before,
*::after {
  box-sizing: border-box;
  outline: none;
  transition: all 0.1s ease-in-out;
}
a,
a:hover,
a:focus {
  text-decoration: none;
  color: inherit;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.cf_form_block {
  width: 100%;
  max-width: 400px;
  position: relative;
  margin-bottom: 16px;
}

.booking-date {
  margin-bottom: 20px;
}

.booking-wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 4rem 0;
}

.cf_form_block > .cf_icon {
  position: absolute;
  top: 15px;
  right: 15px;
  opacity: 0.5;
  filter: grayscale(1);
}
.cf_form_field {
  width: 100%;
  height: 54px;
  padding: 0px 15px;
  padding-right: 40px;
  border: none;
  border: 1px solid #d6d4db;
  /* box-shadow: 0px 0px 0px 1px #919098;
  -webkit-box-shadow: 0px 0px 0px 1px #919098; */
  color: #929199;
  font-family: Product Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  border-radius: 4px;
}
select.cf_form_field {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.response_message {
  font-family: Product Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  margin-top: -15px;
}
.response_message.error {
  color: #ff0000;
}
.response_message.success {
  color: #42ba96;
}
.cf_form_block.error .cf_form_field {
  /* box-shadow: 0px 0px 0px 1px #ff0000;
  -webkit-box-shadow: 0px 0px 0px 1px #ff0000; */
  border-color: #ff0000;
}

.cf_form_field::placeholder {
  font-family: Product Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #929199;
}
.cf_form_field:focus {
  /* box-shadow: 0px 0px 0px 1px #51AA74;
  -webkit-box-shadow: 0px 0px 0px 1px #51AA74; */
  border-color: #252b42;
  color: #252b42;
}
.cf_form_field:focus + .cf_icon {
  opacity: 1;
  filter: grayscale(0);
}
.cf_form_field + .cf_eye_green {
  cursor: pointer;
}

.cf_remember_block input[type="checkbox"] {
  position: absolute;
  left: -9999px;
}
.cf_edit_profile_form_block.cf_inp_focus label {
  color: #51aa74 !important;
}
.cf_edit_profile_form_block.field_active label {
  color: #51aa74 !important;
}
.cf_edit_profile_form_block.field_active .cf_form_field {
  border-color: #51aa74 !important;
  color: #181d25;
}
.cf_edit_profile_form_block.field_active .cf_icon {
  opacity: 1;
  filter: grayscale(0);
}
.cf_remember_block label {
  margin: 0;
  position: relative;
  font-family: Product Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #929199;
  padding-left: 27px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.cf_remember_block label::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 18px;
  height: 18px;
  background-color: #fff;
  border: 1px solid #929199;
  border-radius: 4px;
}
.cf_remember_block label::after {
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  width: 11px;
  height: 5px;
  border-left: 1px solid #51aa74;
  border-bottom: 1px solid #51aa74;
  transform: rotate(-45deg);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}
.cf_remember_block input[type="checkbox"]:checked + label {
  color: #181d25;
}
.cf_remember_block input[type="checkbox"]:checked + label::after {
  opacity: 1;
  visibility: visible;
}
.cf_forgot_block > a {
  font-family: Product Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #181d25;
}

.cf_btn,
.cf_btn:focus {
  display: inline-block;
  min-width: 198px;
  height: 54px;
  border-radius: 4px;
  font-family: Product Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 52px;
  text-align: center;
  color: #ffffff;
  background-color: #38467e;
  border: 1px solid transparent;
  transition: all 0.3s ease-in-out;
}
.cf_btn:hover,
.cf_btn.cf_btn_white:hover {
  background-color: transparent;
  color: #38467e;
  border-color: #38467e;
}
.cf_btn.cf_btn_white,
.cf_btn.cf_btn_white:focus {
  background-color: #fff;
  color: #2e2c33;
  border-color: rgba(46, 44, 51, 0.3);
}
button.cf_btn.cf_totalBtn:disabled{
  color: #fff;
  background-color: #7b7fa8;
  cursor: not-allowed;
}
.cf_page_heading {
  margin-bottom: 8px;
  font-family: Product Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  color: #252b42;
}
.section_heading {
  margin-bottom: 24px;
  font-family: Product Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #2e2c33;
}
.cf_modal .modal-dialog {
  max-width: 590px;
}
.cf_modal .modal-header {
  position: relative;
  padding: 30px 20px 18px;
  border: none;
}
.cf_modal .modal-header .cf_close {
  cursor: pointer;
  position: absolute;
  right: 14px;
  top: 14px;
  width: 40px;
  height: 40px;
}
/* R Css */
.cf_dashboard_header_breadcrumb ul {
  display: flex;
  align-items: center;
}
.cf_dashboard_header_breadcrumb ul li a {
  display: block;
  font-size: 18px;
  padding-right: 30px;
  position: relative;
}
.cf_dashboard_header_breadcrumb ul li:last-child a {
  padding-right: 0;
}
.cf_dashboard_header_breadcrumb ul li a.active {
  color: #42ba96;
  /* min-width: 300px; */
}
.cf_dashboard_header_breadcrumb ul li .arrow_right:after {
  content: url(../images/chevron_right.svg);
  position: absolute;
  top: 50%;
  right: 2px;
  transform: translateY(-50%);
  height: 24px;
  width: 24px;
}

/********** Media CSS **********/
@media (max-width: 767px) {
  .menu_toggle {
    display: none !important;
  }
  .mobile_breadcrumb {
    margin-top: 50px;
  }
  .cf_dashboard_header_breadcrumb {
    position: absolute;
    top: 85px;
    padding-left: 10px;
    left: 0;
  }
  .cf_dashboard_header_breadcrumb ul li a {
    font-size: 16px;
  }
}
