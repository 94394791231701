@font-face {
  font-family: "Product Sans";
  src: url("ProductSans-Bold.woff2") format("woff2"),
    url("ProductSans-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Product Sans";
  src: url("ProductSans-Regular.woff2") format("woff2"),
    url("ProductSans-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
